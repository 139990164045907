<template>
    <v-row>
        <v-col cols="12">
            <v-btn @click="salvar" fixed bottom right fab color="primary"><v-icon dark>mdi-content-save</v-icon></v-btn>

            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <p class="text--primary mb-2"><strong>Disciplina:</strong> {{turma.disciplina}}</p>
                                        <p class="text--primary mb-2"><strong>Professor do curso:</strong> {{turma.usuario_professor_responsavel}}</p>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <p class="text--primary mb-2"><strong>Franquia:</strong> {{turma.franquia}}</p>
                                        <p class="text--primary mb-2"><strong>Turma:</strong> {{turma.turma}}</p>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <p class="text--primary mb-2"><strong>Curso:</strong> {{turma.curso}}</p>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="4"><DatePickerDialogInput v-model="registro.data" label="* Data:" /></v-col>
                            <v-col cols="12" sm="4"><v-text-field hide-details label="* Horário de início:" v-model="registro.datahora_inicio" v-mask="'##:##'" placeholder="Ex: 18:30" /></v-col>
                            <v-col cols="12" sm="4"><v-text-field hide-details label="* Horário de conclusão:" v-model="registro.datahora_fim" v-mask="'##:##'" placeholder="Ex: 22:30" /></v-col>
                            <v-col cols="12"><v-textarea hide-details label="Observação:" v-model="registro.observacao" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'
import {mask} from 'vue-the-mask'
import {mapState} from 'vuex'
import DatePickerDialogInput from "@/Views/Estrutura/Form/DatePickerDialogInput";

export default {
    name: "CursoForm",
    components: {DatePickerDialogInput},
    props : ['id_turma', 'id_aula'],
    directives : {mask},
    data() {
        return {
            turma : {},
            registro : {},
            erro : null
        }
    },
    computed : {
        ...mapState(['baseUrl', 'apiUrl'])
    },
    methods : {
        getAula() {
            return axios.post(`${this.baseUrl}aula/get`, {id_aula : this.id_aula}).then( (res) => {
                let hora_inicio = res.data.datahora_inicio.split(' ')[1].split(':')
                let hora_fim = res.data.datahora_fim.split(' ')[1].split(':')
                this.registro = {
                    ...res.data,
                    data : res.data.datahora_inicio.split(' ')[0],
                    datahora_inicio : `${hora_inicio[0]}:${hora_inicio[1]}`,
                    datahora_fim : `${hora_fim[0]}:${hora_fim[1]}`
                }
            })
        },
        getTurma() {
            return axios.post(`${this.apiUrl}turma/get`, {id_turma : (this.id_turma != undefined ? parseInt(this.id_turma) : parseInt(this.registro.id_turma))}).then( (res) => {
                this.turma = res.data
            })
        },
        salvar() {
            if(!this.id_aula) {
                axios.post(`${this.baseUrl}aula/cadastrar`, {
                    id_turma : this.id_turma,
                    datahora_inicio : this.dataEn(this.registro.data) + ' ' + this.registro.datahora_inicio + ':00',
                    datahora_fim : this.dataEn(this.registro.data) + ' ' + this.registro.datahora_fim + ':00',
                    observacao : this.registro.observacao
                }).then( (res) => {
                    if(res.data.erro == true) {
                        this.erro = res.data.mensagem
                    }
                    else {
                        this.erro = null
                        this.$router.push({path : '/aula/gerenciar/' + res.data.id_aula})
                    }
                })
            }
            else {
                axios.post(`${this.baseUrl}aula/alterar`, {
                    id_aula : this.id_aula,
                    datahora_inicio : this.registro.data + ' ' + this.registro.datahora_inicio + ':00',
                    datahora_fim : this.registro.data + ' ' + this.registro.datahora_fim + ':00',
                    observacao : this.registro.observacao
                }).then( (res) => {
                    if(res.data.erro == true) {
                        this.erro = res.data.mensagem
                    }
                    else {
                        this.erro = null
                        this.$router.push({path : '/aula/gerenciar/' + this.id_aula})
                    }
                })
            }
        },
        async init() {
            if(this.id_aula) {
                await this.getAula()
            }
            await this.getTurma()
        }
    },
    watch : {

    },
    activated : function() {
        this.init()
    }
}
</script>

<style scoped>

</style>
